"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNI_V2_FOX_STAKING_REWARDS_CONTRACTS = exports.UNI_V2_FOX_STAKING_REWARDS_V5_CONTRACT = exports.UNI_V2_FOX_STAKING_REWARDS_V4_CONTRACT = exports.UNI_V2_FOX_STAKING_REWARDS_V3_CONTRACT = exports.UNI_V2_FOX_STAKING_REWARDS_V2_CONTRACT = exports.UNI_V2_FOX_STAKING_REWARDS_V1_CONTRACT = exports.FOXY_STAKING_CONTRACT = exports.SHAPE_SHIFT_ROUTER_CONTRACT = exports.L1_ORBIT_CUSTOM_GATEWAY_CONTRACT = exports.L1_ARBITRUM_GATEWAY_CONTRACT = exports.L2_ARBITRUM_GATEWAY_CONTRACT = exports.L2_ARBITRUM_CUSTOM_GATEWAY_CONTRACT = exports.ARB_RETRYABLE_TX_CONTRACT = exports.ARBITRUM_L2_ERC20_GATEWAY_PROXY_CONTRACT = exports.ARB_SYS_CONTRACT = exports.ARB_OUTBOX_CONTRACT = exports.COWSWAP_SETTLEMENT_CONTRACT_MAINNET = exports.ZRX_OPTIMISM_PROXY_CONTRACT = exports.ZRX_ETHEREUM_PROXY_CONTRACT = exports.CONTRACT_TYPE_TO_ABI = exports.CONTRACT_ADDRESS_TO_ABI = exports.RFOX_WAD = exports.RFOX_REWARD_RATE = exports.RFOX_PROXY_CONTRACT = exports.TS_AGGREGATOR_TOKEN_TRANSFER_PROXY_CONTRACT_MAINNET = exports.THOR_ROUTER_CONTRACT_MAINNET = exports.UNISWAP_V3_POOL_FACTORY_CONTRACT_MAINNET = exports.UNISWAP_V3_QUOTER_CONTRACT_MAINNET = exports.UNISWAP_V2_FACTORY_CONTRACT_MAINNET = exports.UNISWAP_V2_ROUTER_02_CONTRACT_MAINNET = exports.ETH_FOX_POOL_CONTRACT = exports.foxEthStakingContractAddresses = exports.ETH_FOX_STAKING_EVERGREEN_CONTRACT = exports.ETH_FOX_STAKING_V9_CONTRACT = exports.ETH_FOX_STAKING_V8_CONTRACT = exports.ETH_FOX_STAKING_V7_CONTRACT = exports.ETH_FOX_STAKING_V6_CONTRACT = exports.ETH_FOX_STAKING_V5_CONTRACT = exports.ETH_FOX_STAKING_V4_CONTRACT = exports.ETH_FOX_STAKING_V3_CONTRACT = exports.ETH_FOX_STAKING_V2_CONTRACT = exports.ETH_FOX_STAKING_V1_CONTRACT = exports.FOX_TOKEN_CONTRACT = exports.WETH_TOKEN_CONTRACT = void 0;
const viem_1 = require("viem");
const evergreenFarming_1 = require("./abis/evergreenFarming");
const farming_1 = require("./abis/farming");
const iUniswapV2Pair_1 = require("./abis/iUniswapV2Pair");
const thorchainRouter_1 = require("./abis/thorchainRouter");
const uniswapV2Router02_1 = require("./abis/uniswapV2Router02");
const types_1 = require("./types");
exports.WETH_TOKEN_CONTRACT = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
exports.FOX_TOKEN_CONTRACT = '0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d';
// Staking contract addresses as string literals
exports.ETH_FOX_STAKING_V1_CONTRACT = '0xdd80e21669a664bce83e3ad9a0d74f8dad5d9e72';
exports.ETH_FOX_STAKING_V2_CONTRACT = '0xc54b9f82c1c54e9d4d274d633c7523f2299c42a0';
exports.ETH_FOX_STAKING_V3_CONTRACT = '0x212ebf9fd3c10f371557b08e993eaab385c3932b';
exports.ETH_FOX_STAKING_V4_CONTRACT = '0x24fd7fb95dc742e23dc3829d3e656feeb5f67fa0';
exports.ETH_FOX_STAKING_V5_CONTRACT = '0xc14eaa8284feff79edc118e06cadbf3813a7e555';
exports.ETH_FOX_STAKING_V6_CONTRACT = '0xebb1761ad43034fd7faa64d84e5bbd8cb5c40b68';
exports.ETH_FOX_STAKING_V7_CONTRACT = '0x5939783dbf3e9f453a69bc9ddc1e492efac1fbcb';
exports.ETH_FOX_STAKING_V8_CONTRACT = '0x662da6c777a258382f08b979d9489c3fbbbd8ac3';
exports.ETH_FOX_STAKING_V9_CONTRACT = '0x721720784b76265aa3e34c1c7ba02a6027bcd3e5';
exports.ETH_FOX_STAKING_EVERGREEN_CONTRACT = '0xe7e16e2b05440c2e484c5c41ac3e5a4d15da2744';
exports.foxEthStakingContractAddresses = [
    exports.ETH_FOX_STAKING_V9_CONTRACT,
    exports.ETH_FOX_STAKING_V8_CONTRACT,
    exports.ETH_FOX_STAKING_V7_CONTRACT,
    exports.ETH_FOX_STAKING_V6_CONTRACT,
    exports.ETH_FOX_STAKING_V5_CONTRACT,
    exports.ETH_FOX_STAKING_V4_CONTRACT,
    exports.ETH_FOX_STAKING_V3_CONTRACT,
    exports.ETH_FOX_STAKING_V2_CONTRACT,
    exports.ETH_FOX_STAKING_V1_CONTRACT,
    exports.ETH_FOX_STAKING_EVERGREEN_CONTRACT,
];
// Exported as a string literal for contract address discrimination purposes
exports.ETH_FOX_POOL_CONTRACT = '0x470e8de2eBaef52014A47Cb5E6aF86884947F08c';
// Uniswap V2
exports.UNISWAP_V2_ROUTER_02_CONTRACT_MAINNET = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
exports.UNISWAP_V2_FACTORY_CONTRACT_MAINNET = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
// Uniswap V3
exports.UNISWAP_V3_QUOTER_CONTRACT_MAINNET = '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6';
exports.UNISWAP_V3_POOL_FACTORY_CONTRACT_MAINNET = '0x1F98431c8aD98523631AE4a59f267346ea31F984';
// THORChain Contracts
exports.THOR_ROUTER_CONTRACT_MAINNET = '0xd37bbe5744d730a1d98d8dc97c42f0ca46ad7146';
exports.TS_AGGREGATOR_TOKEN_TRANSFER_PROXY_CONTRACT_MAINNET = '0xF892Fef9dA200d9E84c9b0647ecFF0F34633aBe8';
// RFOX on Arbitrum ERC1967Proxy contract address
// Uncomment me if you want to test RFOX with a shorter cooldown period
// export const RFOX_PROXY_CONTRACT: Address = '0x1094c4a99fce60e69ffe75849309408f1262d304'
exports.RFOX_PROXY_CONTRACT = '0xac2a4fd70bcd8bab0662960455c363735f0e2b56';
exports.RFOX_REWARD_RATE = 1n * 10n ** 27n;
exports.RFOX_WAD = 1n * 10n ** 18n;
exports.CONTRACT_ADDRESS_TO_ABI = {
    [exports.ETH_FOX_POOL_CONTRACT]: iUniswapV2Pair_1.I_UNISWAP_V2_PAIR_ABI,
    [exports.ETH_FOX_STAKING_V1_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V2_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V3_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V4_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V5_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V6_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V7_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V8_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_V9_CONTRACT]: farming_1.FARMING_ABI,
    [exports.ETH_FOX_STAKING_EVERGREEN_CONTRACT]: evergreenFarming_1.EVERGREEN_FARMING_ABI,
    [exports.FOX_TOKEN_CONTRACT]: viem_1.erc20Abi,
    [exports.UNISWAP_V2_ROUTER_02_CONTRACT_MAINNET]: uniswapV2Router02_1.UNISWAP_V2_ROUTER_02_ABI,
    // THOR Router Mainnet
    [exports.THOR_ROUTER_CONTRACT_MAINNET]: thorchainRouter_1.THORCHAIN_ROUTER_ABI,
};
exports.CONTRACT_TYPE_TO_ABI = {
    [types_1.ContractType.UniV2Pair]: iUniswapV2Pair_1.I_UNISWAP_V2_PAIR_ABI,
    [types_1.ContractType.ERC20]: viem_1.erc20Abi,
    [types_1.ContractType.ThorRouter]: thorchainRouter_1.THORCHAIN_ROUTER_ABI,
};
// 0x proxy contracts - all EVM chains except Optimism have the same address
exports.ZRX_ETHEREUM_PROXY_CONTRACT = '0xDef1C0ded9bec7F1a1670819833240f027b25EfF';
exports.ZRX_OPTIMISM_PROXY_CONTRACT = '0xDEF1ABE32c034e558Cdd535791643C58a13aCC10';
// Cowswap
exports.COWSWAP_SETTLEMENT_CONTRACT_MAINNET = '0x9008D19f58AAbD9eD0D60971565AA8510560ab41';
// Arbitrum Bridge contracts
exports.ARB_OUTBOX_CONTRACT = '0x0B9857ae2D4A3DBe74ffE1d7DF045bb7F96E4840';
exports.ARB_SYS_CONTRACT = '0x0000000000000000000000000000000000000064';
exports.ARBITRUM_L2_ERC20_GATEWAY_PROXY_CONTRACT = '0x09e9222E96E7B4AE2a407B98d48e330053351EEe';
exports.ARB_RETRYABLE_TX_CONTRACT = '0x000000000000000000000000000000000000006e';
exports.L2_ARBITRUM_CUSTOM_GATEWAY_CONTRACT = '0x096760F208390250649E3e8763348E783AEF5562';
exports.L2_ARBITRUM_GATEWAY_CONTRACT = '0x5288c571Fd7aD117beA99bF60FE0846C4E84F933';
exports.L1_ARBITRUM_GATEWAY_CONTRACT = '0x72ce9c846789fdb6fc1f34ac4ad25dd9ef7031ef';
exports.L1_ORBIT_CUSTOM_GATEWAY_CONTRACT = '0x4Dbd4fc535Ac27206064B68FfCf827b0A60BAB3f';
exports.SHAPE_SHIFT_ROUTER_CONTRACT = '0x6a1e73f12018D8e5f966ce794aa2921941feB17E';
exports.FOXY_STAKING_CONTRACT = '0xee77aa3Fd23BbeBaf94386dD44b548e9a785ea4b';
// Fox Staking Contracts
exports.UNI_V2_FOX_STAKING_REWARDS_V1_CONTRACT = '0xDd80E21669A664Bce83E3AD9a0d74f8Dad5D9E72';
exports.UNI_V2_FOX_STAKING_REWARDS_V2_CONTRACT = '0xc54B9F82C1c54E9D4d274d633c7523f2299c42A0';
exports.UNI_V2_FOX_STAKING_REWARDS_V3_CONTRACT = '0x212ebf9FD3c10F371557b08E993eAaB385c3932b';
exports.UNI_V2_FOX_STAKING_REWARDS_V4_CONTRACT = '0x24FD7FB95dc742e23Dc3829d3e656FEeb5f67fa0';
exports.UNI_V2_FOX_STAKING_REWARDS_V5_CONTRACT = '0xC14eaA8284feFF79EDc118E06caDBf3813a7e555';
exports.UNI_V2_FOX_STAKING_REWARDS_CONTRACTS = [
    exports.UNI_V2_FOX_STAKING_REWARDS_V1_CONTRACT,
    exports.UNI_V2_FOX_STAKING_REWARDS_V2_CONTRACT,
    exports.UNI_V2_FOX_STAKING_REWARDS_V3_CONTRACT,
    exports.UNI_V2_FOX_STAKING_REWARDS_V4_CONTRACT,
    exports.UNI_V2_FOX_STAKING_REWARDS_V5_CONTRACT,
];
